import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from "../layouts";
import Seo from "../components/seo";
import { BreadCrumb, Section, SectionBgWrap,	SectionPageTitle } from '../components/Section'
import CtaSection from '../sections/CtaSection';
import ReviewSectionBg from '../images/review-list-bg.svg';
import ReviewWidget from "../components/ReviewWidget"

const HeroSection = styled(Section)`
	padding-top: 40px;
	height: 400px;
	h1 {
		color: white;
		text-transform: uppercase;
	}
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const ReviewsSection = styled(Section)`
	padding-bottom:80px;
	.section-background {
		position: absolute;
		top: 90px;
		left: 0;
		width: 100%;
		height: calc(100% - 90px);
		background: url(${ReviewSectionBg});
		background-repeat: repeat;
		opacity: 0.2;
	}
`

const ReviewsPage = ({ data, location }) => {
	return(
		<Layout footerCta location={location}>
			<Seo title="See what our Customers have to say about Us" description="We are proud of our customer-centric approach that makes our customers happy. See what some of them have to say about us." />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Review</span>
				</div>
			</BreadCrumb>
			<HeroSection>
				<SectionBgWrap>
					<StaticImage
		        src="../images/reviewbg.png"
		        placeholder="blurred"
		        quality={95}
		        alt="review hero"
		      />
				</SectionBgWrap>
				<div className="container">
					<SectionPageTitle>Customer Reviews</SectionPageTitle>
				</div>
			</HeroSection>
			<ReviewsSection pb="0" pt="0" mpt="0" mpb="0">
				<div className="section-background">
				</div>
				<div className="container">
					<ReviewWidget />
				</div>
			</ReviewsSection>
			<CtaSection />
		</Layout>
	)
}

export default ReviewsPage

