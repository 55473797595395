import React, { useEffect } from "react";
import styled from 'styled-components';

const ReviewList = styled.div`
	margin: -90px auto 0;
  background: white;
  box-shadow: 0 20px 60px rgba(0, 0, 0, .2);
  padding: 80px 20px;
  max-width: 1350px;

  .review {
  	width: 100%;
  	max-width: 950px;
  	margin: 0 auto 60px;
  	padding-bottom: 60px;
  	border-bottom: 1px solid #D0D0D0;
  	display: flex;
  	justify-content: space-between;

  	.mobile-image-wrap {
  		width: 100%;
  		margin: 20px auto;
  		display: none;
  		max-width: 400px;
  	}

  	.image-wrap {
  		width: 200px;
  		height: 200px;

  		.gatsby-image-wrapper {
  			width: 100%;
  			height: 100%;
  		}
  	}

  	@media(max-width: 991px) {
  		.mobile-image-wrap {
  			display: block;
  		}
  		.image-wrap {
  			display: none;
  		}
  	}
  }
`

const ReviewButton = styled.a`
	background: #D51333;
  color:#fff;
  border-radius: 6px;
  box-shadow: 0 6px 0 #982221;
	font-size: 20px;
	font-weight: 600;
  font-family: "Oswald", sans-serif;
  margin: 50px auto;
	padding: 10px 30px;
  display: block;
  width: fit-content;

  &:after {		
    background: #A20000;
  }
  &:hover {
    cursor: pointer;
  }
`


const ReviewWidget = () => {
    useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://cdn.trustindex.io/loader.js?def470013ce010692b569e67263";
        script.async = true;
        script.defer = true;
        document.getElementById("widget-script").appendChild(script)
     },[])
	return(
		
        <ReviewList>
            <div id="widget-script"></div>
            <ReviewButton href="https://www.google.com/search?hl=en-IN&gl=in&q=Buildings+And+More+-+Lake+City,+792+SW+Bascom+Norris+Dr,+Lake+City,+FL+32025,+United+States&ludocid=15587417953557435297&lsig=AB86z5UuLda1sf48oOJ1w2frAhiY#lrd=0x88ef3a47ef23a335:0xd851a20b075653a1,3" target="_blank">
                LEAVE US A REVIEW
            </ReviewButton>
        </ReviewList>
			
	)
}

export default ReviewWidget

